import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Landing.module.css";
import { SelectButton } from "primereact/selectbutton";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import Loading from "../Component/Loading";
import { getEvinaScript, getHeadersApi, sendPinCodeApi, subscribeApi } from "../http/http";
import { toast } from "react-toastify";

const Landing = () => {
  const options = ["fr", "ar"];
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState(false);
  const [sub_id, setSub_id] = useState(null);
  const [showInputField, setShowInputField] = useState(true);
  const [language, setLanguage] = useState(options[0]);
  const [clickId, setClickId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [scriptLoading, setScriptLoading] = useState(true);
  const location = useLocation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false); 

  const newTimestamp = Math.floor(Date.now() / 1000);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const click_id = urlParams.get("click_id");
    const svc = urlParams.get("svc");
    setClickId(click_id);
    fetchDataFromBackend(click_id);
  }, [location]);

  const fetchDataFromBackend = async (click_id) => {
    try {
      const response = await getHeadersApi();
      console.log(response, "headerapi response");
      if (response?.data?.service === "OTP") {
        setOtp(true);
        setMobileNumber("");
        setShowInputField(true);
        setScriptLoading(false);
      } else {
        setMobileNumber(response?.data?.msisdn);
        setShowInputField(false);
        setOtp(false);
        fetchEvina(click_id);
      }
      
    } catch (error) {
      setScriptLoading(false);
      toast.error(
        error?.response?.data?.error ||
          error?.data?.error ||
          error?.message ||
          error
      );
    }
  };

  async function fetchEvina(click_id) {
    try {
      
      setScriptLoading(true);
      
      const script = document.getElementById("evinaScript");
      if (script) {
        script.remove();  
      }

      const response = await getEvinaScript(click_id);
      let scriptContent = JSON.parse(response?.data?.Response);
     

      if (scriptContent) {
        let top_head = document.getElementsByTagName("head")[0];
        let anti_script = document.createElement("script");

        anti_script.id = "evinaScript";
        anti_script.innerHTML = scriptContent?.data?.s;
        top_head.insertBefore(anti_script, top_head.firstChild);

        var event = new Event("DCBProtectRun");
        document.dispatchEvent(event);
        document.addEventListener("gateway-load", (event) => {
          setScriptLoading(false);
        });
      } else {
        setScriptLoading(false);
      }
    } catch (error) {
      setScriptLoading(false);
    }
  }

  const [pack, setPack] = useState("");

  const packs = [
    { name: "Daily", originalName: language === "fr" ? "Tous les jours" : "يومي", code: "D" },
    { name: "Weekly", originalName: language === "fr" ? "Hebdomadaire" : "أسبوعي", code: "W" },
  ];

  const handleSubscription = async (e) => {
    e.preventDefault();
    if (!pack || !pack?.name) {
      toast.error("Please select the pack!");
      return;
    }
    if (!termsAccepted) {
      toast.error("You must accept the terms and conditions.");
      return;
    }
    try {
      let data = {};
      if (otp) {
        data = { msisdn: mobileNumber, pack: pack?.name };
        setLoading(true);
        const response = await sendPinCodeApi(data);
        if (response?.data == 1) {
          navigate("/otp", {
            state: { msisdn: mobileNumber, pack: pack?.name, clickId:clickId },
          });
        } else {
          toast.error(`Failed to Subscribe! send pin code api failure as ${response?.data}`);
        }
        setLoading(false);
      } else {
        data = {ti: clickId, pack: pack?.name ,msisdn:mobileNumber};
        setLoading(true);
        const response = await subscribeApi(data);
        if (response?.data == true) {
          navigate("/subscription-result", { state: { subscribe: "Success" } });
        } else {
          navigate("/subscription-result", { state: { subscribe: "Failed" } });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.data?.message || error?.message || error);
    }
  };

  return (
    <>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className="card flex justify-content-center">
            <SelectButton value={language} onChange={(e) => setLanguage(e.value)} options={options} />
          </div>
        </div>

        <div className={classes.container}>
          <div className={classes.sub_container}>
            <div className={classes.center_form}>
              <form className={classes.subscription_container} onSubmit={handleSubscription}>
                <div className={classes.logo_container}>
                  <div className={classes.logo_header}>
                    <img src="/images/highfive-logo.png" alt="Logo" className={classes.logo} />
                  </div>
                </div>

                {scriptLoading ? (
                  <Loading />
                ) : (
                  <>
                    <div className={classes.input_container}>
                      <IconField iconPosition="left" style={{ width: "100%", display: showInputField ? "block" : "none" }}>
                        <InputIcon className="pi pi-mobile"></InputIcon>
                        <InputText
                          keyfilter="int"
                          placeholder={language === "fr" ? "Entrez le numéro de portable" : "أدخل رقم الجوال"}
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          required
                          style={{ width: "100%" }}
                        />
                      </IconField>
                    </div>

                    <div className={classes.input_container}>
                      <Dropdown
                        value={pack}
                        onChange={(e) => setPack(e.value)}
                        options={packs}
                        optionLabel="originalName"
                        placeholder={
                          language === "fr"
                            ? "Sélectionnez un pack"
                            : language === "ar"
                            ? "اختر باقة"
                            : "Select a Pack"
                        }
                        style={{ width: "100%" }}
                      />
                    </div>

                    {/* Terms and Conditions */}
                    <div className={classes.input_container}>
                      <div className={classes.terms}>
                        <input
                          type="checkbox"
                          id="termsCheckbox"
                          checked={termsAccepted}
                          onChange={() => setTermsAccepted(!termsAccepted)}
                          required
                        />
                        <label htmlFor="termsCheckbox">
                          {language === "fr"
                            ? "J'accepte les termes et conditions"
                            : "أوافق على الشروط والأحكام"}
                        </label>
                      </div>
                    </div>

                    <div className={classes.input_container}>
                      <Button
                        disabled={!termsAccepted || mobileNumber?.length <= 0}
                        label={language === "fr" ? "S'abonner" : "إشترك"}
                        type="submit"
                        raised
                        id="cta_button"
                        loading={loading}
                      />
                    </div>
                  </>
                )}
              </form>

             <div
                className={`${
                  language === "fr"
                    ? classes.terms_section
                    : classes.terms_section_right
                }`}
              >
                <h2 className={classes.title}>
                  {language === "fr"
                    ? "Les présentes conditions générales s'appliquent à l'utilisation de(). En utilisant ce service, le client reconnaît et accepte ces conditions. () est un service d'abonnement. Les frais seront débités de votre compte mobile ou déduits de votre compte prépayé. Des frais d'opérateur ou de données peuvent s'appliquer."
                    : "تنطبق هذه الشروط والأحكام على استخدام (). وباستخدام هذه الخدمة، يقر العميل ويقبل هذه الشروط. () هي خدمة الاشتراك. سيتم تحميل الرسوم على حساب هاتفك المحمول أو خصمها من حسابك المدفوع مسبقًا. قد يتم تطبيق رسوم الناقل أو البيانات."}
                </h2>
                <ul
                  className={`${
                    language === "fr"
                      ? classes.list_section
                      : classes.list_section_right
                  }`}
                >
                  <li>
                    {language === "fr"
                      ? "Méthode d'abonnement"
                      : "طريقة الاشتراك"}
                  </li>
                  <li>
                    {language === "fr"
                      ? "L'utilisateur peut s'abonner en cliquant sur le bouton d'abonnement sur le portail (). Une fois abonné à (), vous recevrez un SMS de confirmation d'abonnement. Suivez les instructions pour télécharger le contenu de votre choix."
                      : "يمكن للمستخدم الاشتراك بالضغط على زر الاشتراك الموجود بالبوابة (). بمجرد الاشتراك في ()، ستتلقى رسالة نصية قصيرة لتأكيد الاشتراك. اتبع التعليمات لتنزيل المحتوى الذي تريده."}
                  </li>
                  <li>{language === "fr" ? "Responsabilité" : "مسؤولية"}</li>
                  <li>
                    {language === "fr"
                      ? "2.1. La responsabilité de l'auteur et de sa société de gestion est limitée au montant des frais d'abonnement effectivement payés."
                      : "2.1. تقتصر مسؤولية المؤلف وشركة إدارته على مبلغ رسوم الاشتراك المدفوعة بالفعل."}
                  </li>

                  <li>
                    {language === "fr"
                      ? "2.2 Nous ne sommes pas responsables des pertes ou des dommages auxquels nous ne pouvions raisonnablement pas nous attendre au début du contrat, ni des pertes ou des dommages causés aux données, aux systèmes ou à l'équipement,"
                      : "2.2 نحن لسنا مسؤولين عن أي خسارة أو ضرر لم يكن من الممكن أن نتوقعه بشكل معقول في بداية العقد، ولا عن فقدان أو تلف البيانات أو الأنظمة أو المعدات،"}
                  </li>
                  <li>
                    {language === "fr"
                      ? "2.3. En ce qui concerne tout prix assuré, la responsabilité du promoteur et de sa société de gestion est limitée aux montants recouvrables en vertu de la police d'assurance applicable."
                      : "2.3. فيما يتعلق بأي جائزة مؤمنة، تقتصر مسؤولية المروج وشركة إدارته على المبالغ القابلة للاسترداد بموجب بوليصة التأمين المعمول بها."}
                  </li>
                  <li>
                    {language === "fr"
                      ? "Conditions générales relatives au marketing par courrier électronique et par téléphone portable :"
                      : "شروط وأحكام التسويق عبر البريد الإلكتروني والهاتف المحمول:"}
                  </li>
                  <li>
                    {language === "fr"
                      ? "En nous communiquant votre numéro de téléphone portable et/ou votre adresse e-mail, vous acceptez que et sa société de gestion vous envoient occasionnellement un e-mail ou un SMS vous informant des dernières offres et promotions disponibles au sein de notre groupe. Si vous souhaitez vous désabonner de l'une de nos diffusions d'emails/de textos, vous trouverez un lien 'UNSUBSCRIBE' dans chaque email."
                      : `من خلال تزويدنا برقم هاتفك المحمول و/أو عنوان بريدك الإلكتروني، فإنك توافق على أن شركة إدارتها سترسل لك أحيانًا بريدًا إلكترونيًا أو رسالة نصية قصيرة لإعلامك بأحدث العروض والعروض الترويجية المتاحة داخل مجموعتنا. إذا كنت ترغب في إلغاء الاشتراك في أحد عمليات بث البريد الإلكتروني/الرسائل النصية لدينا، فستجد رابط "إلغاء الاشتراك" في كل بريد إلكتروني.`}
                  </li>
                  <li>{language === "fr" ? "Cession" : "التنازل"}</li>
                  <li>
                    {language === "fr"
                      ? "() se réserve le droit de céder l'acceptation des présentes Conditions, en tout ou en partie, à tout moment et sans préavis. L'Utilisateur ne peut céder aucun de ses droits ou obligations résultant de son consentement et de son acceptation des présentes Conditions."
                      : "() تحتفظ بالحق في التنازل عن قبول هذه الشروط، كليًا أو جزئيًا، في أي وقت دون إشعار. ولا يجوز للمستخدم التنازل عن أي من حقوقه أو التزاماته الناتجة عن موافقته وقبوله لهذه الشروط."}
                  </li>

                  <li>
                    {language === "fr"
                      ? "ID de l'email de support de contact()"
                      : "معرف البريد الإلكتروني لدعم الاتصال()"}
                  </li>

                  <li>
                    {language === "fr"
                      ? "Modifications des présentes conditions"
                      : "التغييرات في هذه الشروط"}
                  </li>

                  <li>
                    {language === "fr"
                      ? "Nous visons à respecter des normes élevées et nos politiques et procédures sont donc constamment révisées. De temps à autre, nous pouvons mettre à jour nos conditions générales et notre politique de confidentialité afin d'optimiser l'expérience de l'utilisateur et de nous conformer aux obligations légales et réglementaires. Nous vous recommandons donc de consulter régulièrement cette page pour prendre connaissance de nos politiques actuelles."
                      : "نحن نهدف إلى الحفاظ على معايير عالية وبالتالي تتم مراجعة سياساتنا وإجراءاتنا باستمرار. من وقت لآخر، قد نقوم بتحديث الشروط والأحكام وسياسة الخصوصية الخاصة بنا من أجل تحسين تجربة المستخدم والامتثال للالتزامات القانونية والتنظيمية. لذلك نوصيك بمراجعة هذه الصفحة بانتظام لمراجعة سياساتنا الحالية."}
                  </li>

                  <li>
                    {language === "fr"
                      ? "Marques / Logos / Images"
                      : "ماركيز / شعارات / صور"}
                  </li>

                  <li>
                    {language === "fr"
                      ? "Les marques commerciales, les marques de service et les logos (y compris, mais sans s'y limiter, les noms de produits et de vendeurs) sont la propriété de leurs détenteurs respectifs. Les marques/logos/images utilisés n'ont pas été créés avec la participation à cette annonce, et n'ont pas été examinés ou autorisés de quelque manière que ce soit par les propriétaires de cette annonce."
                      : "العلامات التجارية وعلامات الخدمة والشعارات (بما في ذلك، على سبيل المثال لا الحصر، أسماء المنتجات والبائعين) هي ملك لأصحابها. لم يتم إنشاء العلامات التجارية/الشعارات/الصور المستخدمة بالإدخال في هذه القائمة، ولم تتم مراجعتها أو التصريح بها بأي شكل من الأشكال من قبل مالكي هذه القائمة."}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
