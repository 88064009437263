import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Otpsend.module.css";
import { SelectButton } from "primereact/selectbutton";
import logo from "../Images/gamevista.png";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Loading from "../Component/Loading";
import { getEvinaScript, subscribePinApi } from "../http/http";
import { toast } from "react-toastify";

const Otpsend = () => {
  const options = useMemo(() => ["fr", "ar"], []);
  const [code, setCode] = useState("");
  const [language, setLanguage] = useState(options[0]);
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const [pack, setPack] = useState("");
  const [clickId, setClickId] = useState("");
  const [scriptLoading, setScriptLoading] = useState(true);

  useEffect(() => {
    if (location?.state?.msisdn) {
      setMobileNumber(location.state.msisdn);
      setPack(location.state.pack);
      setClickId(location.state.clickId);
      fetchEvina(location.state.pack, location.state.clickId);
    } else {
      navigate("/");
    }
  }, [location, navigate]);

  const fetchEvina = async (ext_ref, pack) => {
    try {
      setScriptLoading(true);

      const script = document.getElementById("evinaScript");
      if (script) {
        script.remove();
      }

      let data = {
        ext_ref: ext_ref,
        pack: pack,
      };
      const response = await getEvinaScript(data);
      const scriptContent = JSON.parse(response?.data?.Response);
      setScriptLoading(false);

      if (scriptContent) {
        const top_head = document.getElementsByTagName("head")[0];
        const anti_script = document.createElement("script");

        anti_script.id = "evinaScript";
        anti_script.innerHTML = scriptContent?.data?.s;
        top_head.insertBefore(anti_script, top_head.firstChild);
        document.dispatchEvent(new Event("DCBProtectRun"));
      }
    } catch (error) {
      console.error("Error loading Evina script", error);
      setScriptLoading(false);
    }
  };

  const handleContinue = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const data = { msisdn: mobileNumber, pinCode: code, pack, ti: clickId };
        const response = await subscribePinApi(data);

        if (response?.data === true) {
          navigate("/subscription-result", {
            state: { subscribe: "Success" },
          });
        } else {
          toast.error("Failed To Subscribe!");
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          "An error occurred";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [mobileNumber, code, pack, clickId, navigate]
  );

  return (
    <div className={classes.main}>
      <header className={classes.header}>
        <SelectButton
          value={language}
          onChange={(e) => setLanguage(e.value)}
          options={options}
        />
      </header>

      <div className={classes.container}>
        <div className={classes.sub_container}>
          <div className={classes.center_form}>
            {scriptLoading ? (
              <Loading />
            ) : (
              <form className={classes.otp_container} onSubmit={handleContinue}>
                <div className={classes.logo_container}>
                  <img src={logo} alt="Logo" className={classes.logo} />
                </div>

                <div className={classes.flex_gap}>
                  <InputText
                    keyfilter="int"
                    placeholder={
                      language === "fr" ? "Confirmer OTP" : "تأكيد رمز OTP"
                    }
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                    style={{ width: "100%" }}
                  />

                  <Button
                    disabled={code.length === 0}
                    type="submit"
                    label={language === "fr" ? "Confirmer" : "تأكيد"}
                    id="cta_button"
                    raised
                    size="small"
                    loading={loading}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otpsend;

