import React, { useState, useCallback } from "react";
import axios from "axios";

const EvinaTest = () => {
  const [uuid, setUuid] = useState(""); // State to hold the generated UUID
  const [response, setResponse] = useState(null); // State to hold the API response
  const [loading, setLoading] = useState(false); // Loading state

  const apiKey = "3d583acbef069d0b91953bc57d466a2fy"; // Replace with your actual API key

  // Function to send Evina Script API request
  const sendEvinaScript = useCallback(async () => {
    try {
      setLoading(true);

      // If uuid is empty, generate a new one
      let generatedUuid = uuid;
      if (!generatedUuid) {
        generatedUuid = crypto.randomUUID();
        setUuid(generatedUuid);
      }

      // Set up the request body (form data)
      const formData = new URLSearchParams();
      formData.append("api_key", apiKey);
      formData.append("service_connection_id", "28");
      formData.append("ti", generatedUuid);
      formData.append("te", "cta_button");
      formData.append("country_code", "DZ");

      // Send the POST request using Axios
      const response = await axios.post(
        "https://trendy.ngvas.com/en/api/post/v1.0/evina.get_script/",
        formData,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      console.log("API Response:", response.data);


      // Set the response in state
      setResponse({
        Response: response.data,
        uuid: generatedUuid,
      });
    } catch (error) {
      console.error("Error sending Evina script request:", error);
      setResponse({ error: "Error fetching data" });
    } finally {
      setLoading(false);
    }
  }, [uuid, apiKey]);

  return (
    <div>
      <button onClick={sendEvinaScript} disabled={loading}>
        {loading ? "Loading..." : "Send Evina Script"}
      </button>

      {/* Displaying the response */}
      {response && (
        <div>
          <h3>API Response</h3>
          <pre>{JSON.stringify(response, null, 2)}</pre> {/* Pretty-printing the response */}
        </div>
      )}
    </div>
  );
};

export default EvinaTest;
