import React, { useEffect, useState } from "react";
import classes from "./SubscriptionResultPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";

const SubscriptionResultPage = () => {
  const options = ["fr", "ar"];
  const [language, setLanguage] = useState(options[0]);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.subscribe == "Failed") {
      setMessage("Failed");
    }
    if (location?.state?.subscribe == "Success") {
      setMessage("Success");
      setTimeout(()=>{
          window.location.href="https://highfivesgames.com";
      },1000)
    } else {
      navigate("/");
    }
  }, [location]);
  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <div className="card flex justify-content-center">
          <SelectButton
            value={language}
            onChange={(e) => setLanguage(e.value)}
            options={options}
          />
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.sub_container}>
          <div className={classes.center_form}>
            <div className={classes.subscription_container}>
              {message == "Success" ? (
                <>
                  <div className={classes.message_container}>
                    <div className={classes.icon_container}>
                      <i className={`pi pi-check ${classes.icon}`}></i>
                    </div>
                    <p className={classes.message}>
                      You have Successfully Subscribed!
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.message_container}>
                    <p className={classes.message}>Failed to Subscribe!</p>
                    <p className={classes.message}>Something Went Wrong!</p>
                  </div>
                  <Button
                    label="Go Back"
                    icon="pi pi-arrow-left"
                    raised
                    onClick={() => navigate("/")}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionResultPage;
