import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Landing from "../Pages/Landing";
import Otpsend from "../Pages/Otpsend";
import HeaderPage from "../Pages/HeaderPage";
import SubscriptionResultPage from "../Pages/SubscriptionResultPage";
import EvenaTest from "../Pages/EvenaTest";
import RedirectPage from "../Pages/RedirectPage";
import Landing2 from "../Pages/Landing2";
import SubscriptionPage from "../Pages/SubscriptionPage";

export const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<RedirectPage />} exact={true}></Route> */}
          <Route path="/" element={<Landing2 />} exact={true}></Route>
          <Route
            path="/subscription-page"
            element={<SubscriptionPage />}
            exact={true}
          ></Route>
          <Route
            path="/subscription-result"
            element={<SubscriptionResultPage />}
            exact={true}
          ></Route>

          <Route path="/evenatest" element={<EvenaTest />} exact={true}></Route>

          <Route path="/otp" element={<Otpsend />} exact={true}></Route>
          <Route path="*" element={<Navigate to="/" />} exact={true}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
