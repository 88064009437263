import axios from "axios";

// const baseUrl = `http://148.251.88.109:9845/api/v1/`;
const baseUrl = `/api/v1/`;

export const getEvinaScript = async (data) => {
  const response = await axios.post(`${baseUrl}getScript`, data);
  return response;
};

// export const getHeadersApi = async (svc,click_id) => {
//   const response = await axios.post(`${baseUrl}header`,{
//     svc:svc,
//     click_id:click_id
//   });
//   return response;
// };

export const getHeadersApi = async () => {
  const response = await axios.post(`${baseUrl}header`);
  return response;
};

export const subscribeApi = async (data) => {
  const response = await axios.post(`${baseUrl}subscribe`, data);
  return response;
};

export const sendPinCodeApi = async (data) => {
  const response = await axios.post(`${baseUrl}sendPinCode`, data);
  return response;
};

export const subscribePinApi = async (data) => {
  const response = await axios.post(`${baseUrl}subscribePin`, data);
  return response;
};

export const redirectApi = async (data) => {
  const response = await axios.post(`${baseUrl}redirect`, data);
  return response;
};
