import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import App from "./App";
import { PrimeReactProvider } from "primereact/api";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const renderApp = () => {
  const root = createRoot(document.getElementById("root")); // Use createRoot from react-dom/client
  root.render(
    <PrimeReactProvider>
      <ToastContainer />
      <App />
    </PrimeReactProvider>
  );
};

renderApp();
